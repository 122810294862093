import { type AxiosError } from 'axios'
import useSWRMutation, { type SWRMutationResponse } from 'swr/mutation'

import {
  type BasicCheckMerchantsResponse,
  NowControllerV4ApiFactory,
  type ProductObjectRequest,
} from '@redteclab/api/clients/bully'

import { useBullyApi } from '../../../base-api-communication'
import { useGlobalConfigContext } from '../../../global-config'

const getNowApiSwrKey = (keyNonce?: string): string | null => {
  const suffix = keyNonce ? `-${keyNonce}` : ''

  return `NowApiSwrGetBasicCheck${suffix}`
}

type FetchArg = { products?: ProductObjectRequest[]; zipCode: string }

const TWELVE_SECONDS_MILLIS = 12_000

export const useNowApiV4SwrGetBasicCheckMutation = ({
  onSuccess,
  swrKeyNonce,
}: {
  onSuccess?: (
    data: BasicCheckMerchantsResponse,
    zipCode: string,
    products?: ProductObjectRequest[],
  ) => void
  swrKeyNonce?: string
} = {}): SWRMutationResponse<
  BasicCheckMerchantsResponse,
  AxiosError,
  string,
  FetchArg
> => {
  const config = useGlobalConfigContext()
  const client = useBullyApi(NowControllerV4ApiFactory)

  const key = getNowApiSwrKey(swrKeyNonce)

  const fetcher = async (
    swrKey: string,
    { arg: { products, zipCode } }: { arg: FetchArg },
  ): Promise<BasicCheckMerchantsResponse> => {
    const response = await client.nowV4TenantLanguageBasicCheckMerchantsPost(
      {
        BasicCheckObject: {
          products,
          zipCode,
        },
        language: config.language,
        tenant: config.tenantAndEnv,
      },
      {
        timeout: TWELVE_SECONDS_MILLIS,
      },
    )

    onSuccess?.(response.data, zipCode, products)

    return response.data
  }

  return useSWRMutation<
    BasicCheckMerchantsResponse,
    AxiosError,
    string | null,
    FetchArg
  >(key, fetcher)
}
