import { type AxiosError, isAxiosError } from 'axios'

type LogMessage = {
  message: unknown
  stackTrace?: string
}

const MAX_STACK_TRACE_LENGTH = 512
const MAX_BODY_LENGTH = 150

const safelyGetAxiosResponseData = (error: AxiosError): string => {
  if (!error.response?.data) {
    return ''
  }

  if (typeof error.response.data === 'string') {
    return error.response.data
  }

  try {
    return JSON.stringify(error.response.data)
  } catch {
    return 'Could not parse response data'
  }
}
/**
 * serialize axios to a more readable format:
 * {
 *   "message": "[GET] https://nonexistent-url.com - Status: 404 status, Response: \"<error details truncated to 150 chars>\"",
 *   "stack": "Error: Request failed with status code 404\n    at ... (stack trace details)"
 * }
 */
const serializeAxiosError = (
  error: AxiosError,
): {
  message: string
  stack: string
} => {
  const url = error.config?.url ?? 'unknown URL'
  const method = error.config?.method?.toUpperCase() ?? 'UNKNOWN_METHOD'
  const status = error.response?.status ?? 'unknown status'
  const responseBody = safelyGetAxiosResponseData(error)
  const message = `[${method}] ${url} - Status: ${status}, Response: ${responseBody.slice(MAX_BODY_LENGTH)}`
  const stack =
    error.stack?.slice(MAX_STACK_TRACE_LENGTH) ?? 'No stack trace available'

  return {
    message,
    stack,
  }
}

/**
 * Errors thrown by the application must extend this error.
 */
export class CustomError extends Error {
  public constructor(
    {
      message,
      name = 'CustomError',
    }: {
      message: string
      name?: string
    },
    options?: ErrorOptions,
  ) {
    super(message, options)

    // eslint-disable-next-line unicorn/custom-error-definition -- Only `CustomError` allows construct dynamic errors on the fly. It could be not efficient to create custom error class for single use case, in this case `CustomError` provides a convenient way to handle the error.
    this.name = name
  }

  public static from(error: CustomError | Error): CustomError {
    if (error instanceof CustomError) {
      return error
    }

    const customError = new CustomError({
      message: error.message,
      name: 'FROM_STANDARD_ERROR',
    })
    customError.stack = error.stack
    customError.cause = error.cause
    customError.name = error.name
    customError.message = error.message

    if (isAxiosError(error)) {
      const serializedAxiosErrorData = serializeAxiosError(error)
      customError.message = serializedAxiosErrorData.message
      customError.stack = serializedAxiosErrorData.stack
    }

    return customError
  }

  public serializeToLogMessage(): LogMessage {
    return {
      message: this.message,
      stackTrace: this.stack,
    }
  }
}
